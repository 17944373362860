

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ChatController } from '@/controllers/chat/ChatController'
import { ProjectTeamResource } from '@/models/projects/ProjectTeamResource'

import TextInput from '@/components/inputs/Text.vue'
import AddComment  from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ChatMessage from '@/components/listItem/ChatMessage.vue'
import { CommentResource } from '@/models/comments/CommentResource'

@Component({
  components: {
    TextInput,
    AddComment,
    SmallLoader,
    ChatMessage
  },
})
export default class Chat extends Vue {

  @Prop()
  private chat!: ChatController

  @Prop()
  private users!: ProjectTeamResource[]

  private createForm: { isSubmitting: boolean, errors: ErrorResponse } = {
    isSubmitting: false,
    errors: {}
  }

  @Watch('chat.items.length')
  private onChatItemsChange(chatCount: number, oldChatCount: number | null) {
    if (chatCount !== oldChatCount && !this.chat.justLoadedNextData) {
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    }
  }

  private async mounted(): Promise<void> {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper?.addEventListener('scroll', this.handleScroll, true)
    this.scrollToBottom()
  }

  private beforeDestroy(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper.removeEventListener('scroll', this.handleScroll, true)
  }

  private handleScroll(e: Event): void {
    // If already loading the next page we wont handle scroll events
    if (this.chat.loadingNextData) return

    // If last page reached we dont need to fetch any new pages so we can skip this
    if (this.chat.lastPageReached) return

    const wrapper = this.$refs.wrapper as HTMLElement
    const SCROLL_OFFSET = 100
    const TOP_REACHED = wrapper.scrollTop < SCROLL_OFFSET
    if (TOP_REACHED) this.reachedTopOfList()
  }

  private reachedTopOfList(): void {
    this.chat.getNextMessages()
  }

  private scrollToBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }

  private async createMessage(message: string): Promise<void> {
    this.createForm.isSubmitting = true
    try {
      await this.chat.createMessage(message)
      this.createForm.errors = {}
      this.scrollToBottom()
    } catch ({errors}) {
      if (errors) {
        this.createForm.errors = errors
      }
    } finally {
      this.createForm.isSubmitting = false
    }
  }

}
