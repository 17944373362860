












import { userModule } from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CommentResource } from '@/models/comments/CommentResource'

import UserImage from '@/components/user/UserImage.vue'

@Component({
  components: {
    UserImage
  }
})
export default class ChatMessage extends Vue {

  @Prop() private message!: CommentResource


  private get isOwnMessage(): boolean {
    return this.message.user_info.id === userModule.user?.id
  }

}
