









































import { Component, Prop, Vue } from 'vue-property-decorator'

import Task from '@/components/tasks/Task.vue'
import Modal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { TasksService } from '@/services/tasks'
import { TaskCreateRequest } from '@/requests/tasks/TaskCreateRequest'
import { TasksController } from '@/controllers/tasks/TasksController'

@Component({
  components: {
    Task,
    Modal,
    TextInput,
    SmallLoader
  },
})
export default class TaskList extends Vue {

  @Prop()
  private tasks!: TasksController

  @Prop({ default: false })
  private canCreateNew!: boolean

  @Prop({ default: false })
  private canUpdateTask!: boolean

  private createTaskForm: { isModalOpen: boolean, isSubmitting: boolean, form: TaskCreateRequest, errors: ErrorResponse } = {
    isModalOpen: false,
    isSubmitting: false,
    form: new TaskCreateRequest({ project_id: this.tasks.project_id }),
    errors: {}
  }

  private service = new TasksService()

  private mounted(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper?.addEventListener('scroll', this.handleScroll, true)
  }

  private beforeDestroy(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper.removeEventListener('scroll', this.handleScroll, true)
  }

  private handleScroll(e: Event): void {
    // If already loading the next page we wont handle scroll events
    if (this.tasks.loadingNextData) return

    // If last page reached we dont need to fetch any new pages so we can skip this
    if (this.tasks.lastPageReached) return

    const wrapper = this.$refs.wrapper as HTMLElement
    const SCROLL_OFFSET = 100
    const BOTTOM_REACHED = (wrapper.scrollTop + SCROLL_OFFSET) > (wrapper.scrollHeight - wrapper.clientHeight)
    if (BOTTOM_REACHED) this.reachedBottomOfList()
  }

  private reachedBottomOfList(): void {
    this.tasks.getNextTasks()
  }

  private async getNext(): Promise<void> {
    this.tasks.getNextTasks()
  }

  private scrollToTop(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    wrapper.scrollTop = 0
  }

  private async createTask(): Promise<void> {
    this.createTaskForm.isSubmitting = true
    try {
      await this.tasks.createTask(this.createTaskForm.form)
      this.closeTaskModal()
      this.scrollToTop()
    } catch ({errors}) {
      if (errors) {
        this.createTaskForm.errors = errors
      }
    } finally {
      this.createTaskForm.isSubmitting = false
    }
  }


  private closeTaskModal(): void {
    this.createTaskForm.isModalOpen = false
    this.createTaskForm.isSubmitting = false
    this.createTaskForm.errors = {}
    this.createTaskForm.form = new TaskCreateRequest({ project_id: this.tasks.project_id })
  }

  private async deleteTask(taskId: number): Promise<void> {
    this.tasks.deleteTask(taskId)
  }

}
