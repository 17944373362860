













































// Components
import { Component, Vue, Prop } from 'vue-property-decorator'
import { userModule } from '@/store'

import Modal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import Dots from '@/components/widgets/DotDotDot.vue'
import UserImage from '@/components/user/UserImage.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { TaskPatchRequest } from '@/requests/tasks/TaskPatchRequest'
import { TaskCollectionResource } from '@/models/tasks/TaskCollectionResource'

@Component({
  components: {
    Dots,
    Modal,
    TextInput,
    UserImage,
    SmallLoader
  },
})
export default class Task extends Vue {
  @Prop()
  private task!: TaskCollectionResource

  @Prop({ default: false })
  private canUpdateTask!: boolean

  private isActivating: boolean = false
  private isDeleting: boolean = false
  private isEditing: boolean = false

  private updateModalOpen: boolean = false
  private updateErrors: ErrorResponse = {}

  private get canPerformAction(): boolean {
    return this.canDeleteTask || this.canEditTask
  }

  private get canDeleteTask(): boolean {
    return this.task.canPerformAction('can_delete_task')
  }

  private get canEditTask(): boolean {
    return this.task.canPerformAction('can_edit_task')
  }

  private get isOwnTask(): boolean {
    return this.task.assigned_by.id === userModule.user?.id
  }

  private async toggleActive(): Promise<void> {
    this.isActivating = true
    this.task.updateForm.active = !this.task.updateForm.active
    try {
      await this.task.patchTask()
    } catch {
      this.task.updateForm.active = !this.task.updateForm.active
    } finally {
      this.isActivating = false
    }
  }

  private async deleteTask(): Promise<void> {
    this.isDeleting = true
    try {
      await this.task.delete()
      this.$emit('deleted', this.task.id)
    } finally {
      this.isDeleting = false
    }
  }

  private async updateTask(): Promise<void> {
    if (!this.task.updateForm.isValid) return

    this.isEditing = true
    try {
      await this.task.patchTask()
    } catch (e) {
      this.updateErrors = e.errors ?? {}
    } finally {
      this.isEditing = false
      this.closeUpdateModal()
    }
  }

  private openUpdateModal() {
    this.task.updateForm = new TaskPatchRequest(this.task)
    this.updateModalOpen = true
  }

  private closeUpdateModal() {
    this.updateModalOpen = false
  }
}
